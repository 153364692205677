import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Client, getAllResponse } from '../../models/client';
import { SearchClientRequest } from '../../dtos/search-client-request';
import { environment } from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ClientsService {

  URL = environment.url + "/api/clients";

  constructor(private http: HttpClient) { }

  getAll(request: SearchClientRequest): Observable<getAllResponse> {
    return this.http.post<getAllResponse>(this.URL, request);
  }

  getAllLower(): Observable<Client[]> {
    return this.http.get<Client[]>(this.URL + "lower");
  }

  block(id: number) {
    return this.http.put(this.URL + "/block/" + id, {});
  }
  verify(id: number) {
    return this.http.put(this.URL + "/active", {
      clientId: id
    });
  }

  deblock(id: number) {
    return this.http.put(this.URL + "/deblock/" + id, {});
  }
}
